/* jshint node: true */
// assets/js/app.js
import $ from 'jquery';
require ('jquery-ui-bundle');

window.$ = $;
window.jQuery = $;


import '../../node_modules/jquery-ui-bundle/jquery-ui.min.css';
import '../../node_modules/jquery-ui-bundle/jquery-ui.theme.min.css';

// import '../css/code.jquery.com/ui/1.12.1/themes/ui-lightness/jquery-ui.min.css';

import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
